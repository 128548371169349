export const subintroClients = [
  { index: 0, base: 'floa_bank.png' },
  { index: 1, base: 'belambra.png' },
  { index: 2, base: 'harmonie_mutuelle.png' },
  { index: 3, base: 'credit_agricole.png' },
  { index: 4, base: 'musee_du_louvre.png' },
  { index: 5, base: 'loreal.png' },
  { index: 6, base: 'biomerieux.png' },
  { index: 7, base: 'maif.png' },
  { index: 8, base: 'banque_populaire.png' },
  { index: 9, base: 'mma.png' },
  { index: 10, base: 'allianz.png' },
  { index: 11, base: 'heineken_experience_green.png' },
  { index: 12, base: 'cardif.png' },
  { index: 13, base: 'louis_vuitton.png' },
  { index: 14, base: 'logo-ratp.png' },
  { index: 15, base: 'fnac-darty-logo.png' },
  { index: 16, base: 'sodexo.png' },
  { index: 17, base: 'engie.png' },
  { index: 18, base: 'direct_assurance.png' },
  { index: 19, base: 'centerparcs.png' },
  { index: 20, base: 'compagnie_des_alpes.png' },
  { index: 21, base: 'axa_france.png' },
  { index: 22, base: 'renault.png' },
  { index: 23, base: 'crt_paris_region.png' },
  { index: 24, base: 'creditmutuel.png' },
  { index: 25, base: 'cic.png' },
  { index: 26, base: 'petzl.png' },
  { index: 27, base: 'chateauform.png' },
  { index: 28, base: 'groupe_rocher.png' },
  { index: 29, base: 'aesio_mutuelle.png' },
];
